<template>
  <div class="edit-box">
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">商品名称</span>
      </div>
      <div class="op-content">
        <van-field v-model="goodInfo.name" placeholder="请输入商品名称" />
      </div>
    </div>
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">商品类别</span>
      </div>
      <div class="op-content">
        <van-field
          v-model="goodInfo.type"
          placeholder="请输入商品类别"
          maxlength="2"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">限购数量</span>
      </div>
      <div class="op-content">
        <van-field
          v-model="goodInfo.reset"
          type="digit"
          placeholder="请输入限购数量"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">单位</span>
      </div>
      <div class="op-content">
        <van-field
          v-model="goodInfo.unit"
          placeholder="请输入单位"
          maxlength="1"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">原价</span>
      </div>
      <div class="op-content">
        <van-field
          v-model="goodInfo.price"
          type="number"
          placeholder="请输入商品原价"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">抢购售价</span>
      </div>
      <div class="op-content">
        <van-field
          v-model="goodInfo.now_price"
          type="number"
          placeholder="请输入抢购售价"
        />
      </div>
    </div>
    <div class="item-box">
      <div class="label">
        <span class="need-tips">*</span>
        <span class="name">商品封面图</span>
      </div>
      <div class="op-content">
        <van-uploader :after-read="afterRead" v-model="fileList" :max-count="1">
          <div class="upload-customer-box">上传</div>
        </van-uploader>
      </div>
    </div>
    <div class="buy-btn" @click.stop="toSave">
      {{ route.query.id ? '保存' : '创建并发送到群' }}
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Toast } from 'vant'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { fileUpload } from '@/service/upload.service.js'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
const route = useRoute()
const router = useRouter()
const store = useStore()
const goodInfo = ref({
  name: '【今日特价】',
  img: '',
  reset: '',
  type: '',
  stock: 10,
  unit: '',
  price: '',
  now_price: ''
})
const fileList = ref([
  {
    url: '',
    status: 'done',
    message: ''
  }
])
if (route.query.id) {
  const res = store.state.mockGoods.list.find(
    (item) => item?.id == route.query.id
  )
  if (res) {
    goodInfo.value = JSON.parse(JSON.stringify(res))
    fileList.value[0].url = res.img
  }
} else {
  fileList.value = []
}
const afterRead = (file) => {
  // 此时可以自行将文件上传至服务器
  console.log(file)
  fileList.value[0].status = 'uploading'
  // 创建一个空对象实例
  let formData = new FormData()
  // 调用append()方法添加数据
  formData.append('file', file.file)
  formData.append('file_type', 1)
  fileUpload(formData).then((res) => {
    if (res.code == 200) {
      fileList.value[0].status = 'done'
      fileList.value[0].url = res.data.file_url
    } else {
      fileList.value[0].status = 'failed'
    }
    console.log('file', file)
  })
}
const handleShare = async (item) => {
  Toast.loading({
    message: '加载中',
    forbidClick: true,
    duration: 0
  })
  // recordDynamic(item.goods_id)
  try {
    let title = item.name
    let imgUrl = item.img
    let page = `pages/goodsDetail/index.html?goods_id=${item.id}&is_share=1`
    // page = await formatParamsDistribution(
    //   page,
    //   props.externalUserId,
    //   props.chatId,
    //   'b2c_goods'
    // )

    // 如果存在埋点id加入埋点id
    // if (share_data_id.value) page = `${page}&share_data_id=${share_data_id.value}`

    let shareObj = {
      appid: 'wxbecd55f783f6a0cd', //小程序的appid 群店:wxbecd55f783f6a0cd; aosifen: wx754859a6c762e762
      title,
      imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
      page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
    }
    // console.log('发送前参数', shareObj)
    const { sendStatus } = await sendChatMessageAllType('miniprogram', {
      miniprogram: { ...shareObj }
    })
    Toast.clear()
    return true

    // 如果发送成功， 则调用记录动态接口
    // if (sendStatus) recordDynamic(item.goods_id)
  } catch (e) {
    console.log('报错', e)
    Toast.clear()
    return false
  }
}
const toSave = async () => {
  if (!goodInfo.value.name) {
    return Toast.fail('请输入商品名称')
  }
  if (!goodInfo.value.type) {
    return Toast.fail('请输入商品类别')
  }
  if (!goodInfo.value.reset) {
    return Toast.fail('请输入限购数量')
  }
  if (!goodInfo.value.unit) {
    return Toast.fail('请输入限购单位')
  }
  if (!goodInfo.value.price) {
    return Toast.fail('请输入商品原价')
  }
  if (!goodInfo.value.now_price) {
    return Toast.fail('请输入抢购售价')
  }
  if (!fileList.value[0]?.url) {
    return Toast.fail('请上传商品封面图')
  }
  let res = goodInfo.value
  res.img = fileList.value[0].url
  if (!res.id) {
    res.id = new Date().getTime()
    await handleShare(res)
  }
  console.log('验证通过', goodInfo.value, fileList.value)
  store.commit('mockGoods/SET_LIST', res)
  router.go(-1)
}
console.log('route', route)
</script>
<style>
#app {
  background-color: #fff;
}
</style>
<style lang="less" scoped>
.upload-customer-box {
  width: 90px;
  height: 90px;
  border: 1px dashed #262626;
  border-radius: 4px;
  position: relative;
  text-align: center;
  padding-top: 50px;
  &::after {
    position: absolute;
    content: '';
    width: 16px;
    height: 1px;
    left: 50%;
    margin-left: -8px;
    top: 32px;
    background-color: #262626;
  }
  &::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 16px;
    left: 50%;
    top: 24px;
    background-color: #262626;
  }
}
.buy-btn {
  width: calc(100vw - 30px);
  margin: 0 auto;
  height: 44px;
  line-height: 44px;
  background: #1773fa;
  border-radius: 4px;
  text-align: center;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  position: fixed;
  left: 15px;
  z-index: 3;
  bottom: calc(32px + env(safe-area-inset-bottom));
}
.edit-box {
  padding: 12px;
  height: calc(100vh - 48px - env(safe-area-inset-bottom));
  overflow-y: auto;
  .item-box {
    margin-bottom: 12px;
  }
}
.label {
  .need-tips {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #ff3b34;
    margin-right: 4px;
  }
  .name {
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #262626;
  }
}
.op-content {
  margin-top: 8px;
  .van-cell {
    padding: 0 14px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #8c8c8c;
    letter-spacing: 0;
    :deep(.van-field__control) {
      color: #8c8c8c;
    }
  }
}
</style>
